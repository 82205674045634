import { handleErrorWithSentry } from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { init } from './lib/client/error-tracking';

init();

const customErrorHandler: HandleClientError = ({ error, event }) => {
    console.error('An error occurred on the client side:', error, event);
};

export const handleError = handleErrorWithSentry(customErrorHandler);
